var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{key:'farmer-detail' + _vm.componentKey,staticClass:"farmer-detail"},[_c('v-col',{attrs:{"md":"4","xl":"3"}},[(_vm.$_sys.isAllowed('farmer-update'))?_c('farmer-assign-modal',{attrs:{"data":_vm.ff,"dataKey":_vm.farmerAssignModal},on:{"success":function($event){return _vm.getData()}}}):_vm._e(),_c('v-card',{staticClass:"scooping-visit-detail-card farmer-card mb-5",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left-circle")]),_c('h5',{staticClass:"mb-0 pb-0"},[_vm._v("Detail Petani")])],1),_c('div',{staticClass:"farmer-side-wrapper"},[_c('h5',{staticClass:"side-title"},[_vm._v("Profil Petani")]),_c('div',{staticClass:"farmer-info d-flex flex-row"},[_c('div',{staticClass:"farmer-info-text d-flex flex-column"},[_c('div',{staticClass:"farmer-info-list"},[_c('v-icon',[_vm._v("mdi-account-badge-outline")]),_c('span',[_vm._v(_vm._s(_vm.mainData.name)+" "),(_vm.mainData.nickname)?_c('span',[_vm._v("("+_vm._s(_vm.mainData.nickname)+")")]):_vm._e()])],1),_c('div',{staticClass:"farmer-info-list"},[_c('v-icon',[_vm._v("mdi-account-badge-outline")]),_c('span',[_vm._v(_vm._s(_vm.mainData.farmer_no))])],1),_c('div',{staticClass:"farmer-info-list"},[_c('v-icon',[_vm._v("mdi-map-marker-radius-outline")]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.mainData.target_areas_name?.replace(/_/g, " "))+" ")])],1),_c('div',{staticClass:"d-flex flex-row mt-3"},[_c('div',{staticClass:"action-button d-flex flex-column",staticStyle:{"flex-wrap":"wrap"}},[(
                    _vm.$_sys.isAllowed('farmer-verification-create') &&
                    _vm.mainData.approve == 0 &&
                    this.$store.state.tmpProgramYear ==
                      this.$_config.programYear.model
                  )?_c('v-btn',{staticClass:"mr-3 mb-2",attrs:{"variant":"success","disabled":Array.isArray(_vm.trees) && _vm.trees.length == 0,"small":""},on:{"click":function($event){return _vm.onVerification()}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-check-underline")]),_c('span',{staticClass:"ml-1"},[_vm._v("Verifikasi")])],1):(
                    _vm.mainData.approve == 1 &&
                    _vm.$_sys.isAllowed('farmer-unverification-create') &&
                    this.$store.state.tmpProgramYear ==
                      this.$_config.programYear.model
                  )?_c('v-btn',{staticClass:"mr-3 mb-2",attrs:{"variant":"danger","small":""},on:{"click":function($event){return _vm.onVerification()}}},[_c('span',[_vm._v("Unverifikasi ")])]):_vm._e(),(_vm.$_sys.isAllowed('farmer-update'))?_c('v-btn',{staticClass:"mr-3 mb-2 py-2 d-flex flex-row align-items-center",attrs:{"variant":"info","small":""},on:{"click":function($event){_vm.farmerAssignModal += 1}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-calendar-end")]),_c('span',{staticClass:"ml-1"},[_vm._v("Assign Program Year")])],1):_vm._e()],1)])]),_c('div',{staticClass:"farmer-info-avatar-wrapper"},[(!_vm.mainData.farmer_profile)?_c('div',{staticClass:"farmer-info-avatar"},[_vm._v(" "+_vm._s(_vm.avatarHelper(_vm.mainData.name)?.toUpperCase())+" ")]):_c('div',{staticClass:"farmer-profile hover-pointer",style:({
                'background-image':
                  'url(' +
                  `${_vm.$_config.baseUrlUpload}/${_vm.mainData.farmer_profile}` +
                  ')',
              }),attrs:{"alt":""},on:{"click":function($event){return _vm.showLightbox(
                  `${_vm.$_config.baseUrlUpload}/${_vm.mainData.farmer_profile}`
                )}}})])]),_vm._l((_vm.fieldSide),function(item,i){return _c('div',{key:'info-list' + i,staticClass:"farmer-side-list-wrapper"},[_c('h5',{staticClass:"side-title"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"farmer-side-list"},_vm._l((item.items),function(data,j){return _c('div',{key:'info-list' + i + j,staticClass:"farmer-side-item"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(data.label))]),_c('div',{staticClass:"value"},[(data.view_data == 'program_year')?_c('span',[_c('span',{staticClass:"badge bg-primary mr-1"},[_vm._v(_vm._s(_vm.$store.state.tmpProgramYear))])]):(data.view_data == 'address')?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getAddress())+" ")]):(data.view_data == 'ff_name')?_c('span',[_c('span',[_vm._v(_vm._s(_vm.mainData?.field_facilitators_name))]),_c('div',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"badge bg-light"},[_vm._v(_vm._s(_vm.mainData?.user_id))])])]):(data.value == 'photo')?_c('span',[_c('div',{staticClass:"item-photo hover-pointer",style:({
                      'background-image':
                        'url(' +
                        `${_vm.$_config.baseUrlUpload}/${
                          _vm.mainData[data.view_data]
                        }` +
                        ')',
                    }),on:{"click":function($event){return _vm.showLightbox(
                        `${_vm.$_config.baseUrlUpload}/${
                          _vm.mainData[data.view_data]
                        }`
                      )}}})]):(data.class && typeof data.class == 'object')?_c('span',{class:{
                    [data.class[_vm.mainData[data.view_data]]]: true,
                  }},[(typeof data.view_data == 'string' && !data.value)?_c('span',[_vm._v(_vm._s(_vm.mainData[data.view_data]))]):(typeof data.value == 'object')?_c('span',[_vm._v(_vm._s(data.value[_vm.mainData[data.view_data]]))]):_vm._e()]):_c('span',{class:{
                    [data.class]: true,
                  }},[(typeof data.value === 'object')?_c('span',[_vm._v(" "+_vm._s(data.value[_vm.mainData[data.view_data]])+" ")]):_c('span',[_vm._v(_vm._s(_vm.mainData[data.view_data]))])])])])}),0)])})],2)],1)],1),_c('v-col',{attrs:{"lg":"8"}},[_c('farmer-detail-working-area',{attrs:{"workingAreas":_vm.workingAreas,"mainData":_vm.mainData},on:{"refresh":function($event){return _vm.getData()}}}),_c('farmer-detail-tree',{attrs:{"trees":_vm.trees,"mainData":_vm.mainData,"pivots":_vm.ff}}),_c('farmer-detail-land',{attrs:{"lands":_vm.lands,"mainData":_vm.mainData}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }