var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"70%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',[_vm._v("Assign Data FF ")]),_c('v-card-text',{staticClass:"ff-email-wrapper"},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',{staticClass:"assign-ff-wrapper"},[_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    type: 'select',
                    label: 'Management Unit',
                    api: 'GetManagementUnit',
                    validation: ['required'],
                    param: {
                      program_year: 2024,
                    },
                    option: {
                      getterKey: 'data.result',
                      default_label: _vm.formData.managementunits_name,
                      list_pointer: {
                        label: 'name',
                        code: 'mu_no',
                        display: ['name'],
                      },
                    },
                  }},model:{value:(_vm.formData.mu_no),callback:function ($$v) {_vm.$set(_vm.formData, "mu_no", $$v)},expression:"formData.mu_no"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    type: 'select',
                    label: 'Target Area',
                    api: 'GetTargetArea',
                    validation: ['required'],
                    param: {
                      mu_no: _vm.formData.mu_no,
                    },
                    option: {
                      default_label: _vm.formData.target_areas_name,
                      getterKey: 'data.result',
                      list_pointer: {
                        label: 'name',
                        code: 'area_code',
                        display: ['name'],
                      },
                    },
                  },"disabled":!_vm.formData.mu_no},model:{value:(_vm.formData.area_code),callback:function ($$v) {_vm.$set(_vm.formData, "area_code", $$v)},expression:"formData.area_code"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    type: 'select',
                    label: 'Working Area / Desa',
                    api: 'GetDesaByKecamatanTA_new',
                    validation: ['required'],
                    param: {
                      ta_no: _vm.formData.area_code,
                    },
                    option: {
                      // getterKey: 'data.result',
                      default_label: _vm.formData.desas_name,
                      list_pointer: {
                        label: 'name',
                        code: 'kode_desa',
                        display: ['name'],
                      },
                    },
                  },"disabled":!_vm.formData.area_code},model:{value:(_vm.formData.village),callback:function ($$v) {_vm.$set(_vm.formData, "village", $$v)},expression:"formData.village"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                    type: 'select',
                    label: 'Field Facilitator',
                    api: 'GetFFAllWeb_new',
                    validation: ['required'],
                    option: {
                      // getterKey: 'data.result',
                      default_label: _vm.formData.ff_name,
                      list_pointer: {
                        label: 'name',
                        code: 'ff_no',
                        display: ['name', 'target_areas_name'],
                      },
                    },
                  }},model:{value:(_vm.formData.ff_no),callback:function ($$v) {_vm.$set(_vm.formData, "ff_no", $$v)},expression:"formData.ff_no"}})],1),_c('v-col',{staticClass:"d-flex flex-row justify-content-center",attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"d-flex flex-row align-items-center",attrs:{"variant":"info","type":"submit"}},[_c('v-icon',[_vm._v("mdi-account-edit")]),_c('span',[_vm._v("Assign FF")])],1)],1)],1)],1)]}}],null,true)})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }