var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"rounded-xl elevation-1 mx-3 pa-1",attrs:{"headers":_vm.header,"items":_vm.data,"search":'',"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800","footer-props":{
    showCurrentPage: false,
    showFirstLastPage: false,
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"geko-list-header d-flex flex-row justify-content-between px-5 py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5"},[_c('h4',[_vm._v("Lahan Petani")])]),_c('div',{staticClass:"land-filters"},_vm._l((_vm.lands.filter(
            (obj1, i, arr) =>
              arr.findIndex(
                (obj2) => obj2.program_year === obj1.program_year
              ) === i
          )),function(item,i){return _c('v-btn',{key:`farmer-land-filter-${i}`,staticClass:"ml-1",attrs:{"variant":item.program_year == _vm.programYear ? 'success' : 'light'},on:{"click":function($event){return _vm.getData(item.program_year)}}},[_vm._v(_vm._s(item.program_year))])}),1)])]},proxy:true},{key:"item.indicator",fn:function({ item }){return [_c('div',{staticClass:"indicator-wrapper pt-1"},[_c('div',{staticClass:"indicator",class:{
          warning:
            item.approve == 0 && item.updated_gis.toLowerCase() == 'belum',
          success: item.approve == 2 && item.eligible_status == 2,
          info:
            (item.approve == 0 &&
              item.updated_gis.toLowerCase() == 'sudah') ||
            (item.approve == 2 && item.eligible_status == 1),
          primary: item.approve == 1 && item.fc_complete_data !== null,
          danger:
            item.approve == 3 ||
            (item.approve == 2 && item.eligible_status == 0) ||
            (item.approve == 1 && item.fc_complete_data == null),
        }})])]}},{key:"item.action",fn:function({ item }){return [_c('div',{staticClass:"geko-list-actions-wrapper d-flex flex-column"},[_c('div',{staticClass:"geko-list-actions"},[(_vm.$_sys.isAllowed('lahan-detail'))?_c('button',{staticClass:"geko-list-action-view",on:{"click":function($event){return _vm.$router.push({
              path: 'lahan-v2',
              query: {
                view: 'detail',
                id: item.id,
              },
            })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-outline")])],1):_vm._e()])])]}},{key:"item.index",fn:function({ index }){return [_c('div',{staticClass:"d-flex flex-row justify-content-center"},[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"item.land_id",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-link"},[_vm._v("#"+_vm._s(item.lahan_no))]),_c('span',{staticClass:"font-weight-500"},[_vm._v(_vm._s(item.document_no))])])]}},{key:"item.land_area",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row font-weight-bold justify-content-end min-w-100px"},[_vm._v(" "+_vm._s(_vm._f("parse")(item.land_area,"ts"))+" m² ")])]}},{key:"item.ff_id",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.field_facilitators_name))]),_c('span',{staticClass:"badge bg-light"},[_vm._v(_vm._s(item.user_id))])])]}},{key:"item.farmer_id",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.farmer_name))]),_c('span',{staticClass:"badge bg-light"},[_vm._v(_vm._s(item.farmer_no))])])]}},{key:"item.pohon_kayu",fn:function({ item }){return [(
        Array.isArray(item.total_from_detail) &&
        item.total_from_detail.length > 0
      )?_c('span',{staticClass:"d-block text-center"},[_vm._v(" "+_vm._s(_vm._f("parse")(item.total_from_detail[0].pohon_kayu_detail,"ts"))+" ")]):_vm._e()]}},{key:"item.pohon_mpts",fn:function({ item }){return [(
        Array.isArray(item.total_from_detail) &&
        item.total_from_detail.length > 0
      )?_c('span',{staticClass:"d-block text-center"},[_vm._v(" "+_vm._s(_vm._f("parse")(item.total_from_detail[0].pohon_mpts_detail,"ts"))+" ")]):_vm._e()]}},{key:"item.kayu_mpts",fn:function({ item }){return [(
        Array.isArray(item.total_from_detail) &&
        item.total_from_detail.length > 0
      )?_c('span',{staticClass:"font-weight-bold d-block text-center"},[_vm._v(_vm._s(_vm._f("parse")((item.total_from_detail[0].pohon_kayu_detail + item.total_from_detail[0].pohon_mpts_detail),"ts")))]):_vm._e()]}},{key:"item.complete_data",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row justify-content-center"},[_c('span',{staticClass:"badge",class:{
          'bg-info': item.complete_data == 0,
          'bg-danger': item.complete_data == null,
          'bg-success': item.complete_data == 1,
        }},[_vm._v(_vm._s(item.complete_data == 1 ? "Lengkap" : item.complete_data == 0 ? "Belum Lengkap" : "Menunggu Verifikasi FC"))])])]}},{key:"item.status",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row text-no-wrap",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"badge",class:{
          'bg-warning':
            item.approve == 0 && item.updated_gis.toLowerCase() == 'belum',
          'bg-info':
            item.approve == 0 && item.updated_gis.toLowerCase() == 'sudah',
          'bg-primary': item.approve == 1 && item.fc_complete_data != null,
          'bg-success': item.approve == 2,
          'bg-danger':
            item.approve == 3 ||
            (item.approve == 1 && item.fc_complete_data == null),
        }},[(item.approve == 1 && item.fc_complete_data == null)?_c('span',[_vm._v("Data Bermasalah")]):(
            item.approve == 0 && item.updated_gis.toLowerCase() == 'belum'
          )?_c('span',[_vm._v("Belum Diverifikasi")]):(
            item.approve == 0 && item.updated_gis.toLowerCase() == 'sudah'
          )?_c('span',[_vm._v("Diverifikasi GIS")]):(item.approve == 1)?_c('span',[_vm._v("Diverifikasi FC")]):(item.approve == 2)?_c('span',[_vm._v("Terverifikasi")]):(item.approve == 3)?_c('span',[_vm._v("Force Majeure")]):_vm._e()])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }