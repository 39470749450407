var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"rounded-xl elevation-1 mx-3 pa-1 mb-4",attrs:{"headers":_vm.header,"items":_vm.data,"search":'',"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800","footer-props":{
    showCurrentPage: false,
    showFirstLastPage: false,
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('farmer-assign-ff-modal',{attrs:{"dataKey":_vm.assignFfModal},on:{"refresh":function($event){return _vm.$emit('refresh', true)}}}),_c('div',{staticClass:"geko-list-header px-5 py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 mb-3 pb-3 bordered-bottom d-flex flex-row align-items-center justify-content-between"},[_c('h4',[_vm._v("FF (Area Kerja)")]),_c('div',{staticClass:"buttons"},[(_vm.$_sys.isAllowed('farmer-working-area-create'))?_c('v-btn',{attrs:{"variant":"info"},on:{"click":function($event){_vm.assignFfModal += 1}}},[_c('v-icon',[_vm._v("mdi-account-edit")]),_c('span',[_vm._v("Assign FF")])],1):_vm._e()],1)]),_c('div',{staticClass:"d-flex flex-row align-items-center mt-2 bg-info-light px-4 py-3 br-8"},[_c('v-icon',{staticClass:"text-info",attrs:{"large":""}},[_vm._v("mdi-information")]),_c('span',{staticClass:"text-info pl-3"},[_vm._v(" Informasi area kerja petani apabila petani memiliki lahan yang berada di luar area kerja ")])],1)])]},proxy:true},{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.action",fn:function({ item }){return [_c('div',{staticClass:"geko-list-actions-wrapper d-flex flex-column"},[_c('div',{staticClass:"geko-list-actions"},[(_vm.$_sys.isAllowed('field-facilitator-detail'))?_c('button',{staticClass:"geko-list-action-view",on:{"click":function($event){return _vm.$router.push({
              path: '/field-facilitator-v2',
              query: {
                view: 'detail',
                ff_no: item.ff_no,
              },
            })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-outline")])],1):_vm._e(),(_vm.$_sys.isAllowed('farmer-working-area-delete'))?_c('button',{staticClass:"geko-list-action-delete",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()])])]}},{key:"item.ff_id",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-500"},[_vm._v(_vm._s(item.field_facilitators_name))]),_c('div',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"badge bg-light"},[_vm._v(_vm._s(item.ff_no))])])])]}},{key:"item.program_year",fn:function({ item }){return [_c('span',{staticClass:"badge bg-primary"},[_vm._v(_vm._s(item.program_year))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }